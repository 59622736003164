<template>
  <div class="balance">
    <span v-if="label" class="balance-label">{{ label }}</span>
    <div class="input-balance" @click="toggleSelect" v-click-outside="closeDropdown">
      <div class="select__content">
        <atomic-image
          class="img"
          :src="`${iconUrl}/${balanceFormat.currency}.svg`"
          :defaultImage="DEFAULT_IMG"
        />
        <div class="amount">{{ balanceFormat.amount }}</div>
        <div class="label">{{ balanceFormat.currency }}</div>
      </div>
      <modal-currencies
        :is-open="isShow"
        @close="isShow = false"
        @click.stop
        @changeActiveAccount="onChangeAccount"
        :pay-method="payMethod"
        :pay-definition="payDefinition"
      />
      <div class="select" :class="{ 'is-open': isShow }">
        <atomic-icon class="icon-expand" id="arrow_expand-close" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
import { DEFAULT_IMG } from '@skeleton/consts/staticPath';

  const walletStore = useWalletStore();
  const props = defineProps<{
    changeMobileForm: Function;
    label?: string;
    payMethod?: string;
    payDefinition?: string;
  }>();

  const { formatBalance } = useProjectMethods();
  const { getPlayerBonuses } = useBonusStore();
  const { activeAccount } = storeToRefs(walletStore);
  const transactionStore = useTransactionStore();
  const { iconUrl } = useDynamicIcon('currency');

  const { walletModalType } = storeToRefs(transactionStore);

  const isShow = ref<boolean>(false);

  const balanceFormat = computed(() => formatBalance(activeAccount.value?.currency, activeAccount.value?.balance));

  const toggleSelect = () => {
    if (!isShow.value) {
      getPlayerBonuses();
    }

    isShow.value = !isShow.value;
  };

  const fetchData = (methodsFunction: () => Promise<any>) => {
    const { getDepositBonuses } = useBonusStore();
    methodsFunction(), getDepositBonuses(activeAccount.value?.currency as string);
  };

  const onChangeAccount = (): void => {
    if (walletModalType.value === 'deposit') {
      fetchData(walletStore.getDepositMethods);
    } else {
      fetchData(walletStore.getWithdrawMethods);
    }
    props.changeMobileForm();
  };

  const closeDropdown = () => {
    if (isShow.value) isShow.value = false;
  };
</script>

<style src="~/assets/styles/components/modal/balance.scss" lang="scss" />
